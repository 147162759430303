import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import BmBmMediation from "../components/BmBmMediation";

const BmBmMediationPage = ({ data }) => (
  <Layout>
    <BmBmMediation data={data} />
  </Layout>
);

export default BmBmMediationPage;

export const query = graphql`
  query {
    buddah: file(relativePath: { eq: "buddah-hand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
