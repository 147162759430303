import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { Row, Col } from "react-styled-flexboxgrid";

import { Primary, Alternate } from "../../components/Section";
import { HrefButtonGhostInverse } from "../../components/Button";
import LogoMediation from "../../images/logo-mediation.svg";
import { rem } from "polished";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-rock-canyon.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 35%;
`;
const StyledLogoMediation = styled.img`
  height: ${rem(300)};
  margin: 0;
`;

const BmBmMediation = ({ data }) => (
  <section>
    <StyledBackgroundSection centered height={350}>
      <Row>
        <Col xs={12}>
          <h1>MJ MD Mediation & Coaching</h1>
          <h2>Let’s get it done. Avoid the courtroom!</h2>
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <StyledLogoMediation src={LogoMediation} alt="mj md mediation logo" />
          <h2>Targeted!</h2>
          <p>
            Yes, you are targeted. You are a special person. You are very
            important! And yes, you deserve peace. Your children deserve peace.
            Peace is what we live for at MJ MD Coaching while creating a new
            history for your family.
          </p>
        </Col>
      </Row>
    </Primary>

    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Peaceful Solutions</h2>
          <p>
            MJ MD Mediation & Coaching is a service offered by our Condition
            Sports Management. MJ MD Coaching uses the book Forever My Daddy:
            Denied as a workbook to guide couples and individuals into a healthy
            and peaceful co-parenting relationship while avoiding the courtroom
            and saving money. MJ MD Mediation & Coaching leads health
            professionals, professional athletes, and corporate leaders through
            conflicts such as divorce.
          </p>
          <HrefButtonGhostInverse
            href="https://www.mjmdcoaching.com"
            target="_blank"
          >
            Learn more
          </HrefButtonGhostInverse>
        </Col>
        <Col xs={12} md={6}>
          <Img fluid={data.buddah.childImageSharp.fluid} alt="Buddah Hand" />
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default BmBmMediation;
